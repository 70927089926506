import { Component, Input, OnInit } from '@angular/core';
import { CustomSnackBarComponent, helper } from 'src/app/shared';
import { DynamicTagService } from 'src/app/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-dynamic-tags',
  templateUrl: './dynamic-tags.component.html',
  styleUrls: ['./dynamic-tags.component.scss'],
})
export class DynamicTagsComponent implements OnInit {
  @Input() tagType = 'general';

  dynamicTags = [];

  constructor(
    private dynamicTag: DynamicTagService,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.getTags();
  }

  getTags() {
    this.dynamicTag
      .getByCategory(`category=${this.tagType}`)
      .subscribe((res: any) => {
        this.dynamicTags = res.data;
      });
  }

  copyTag(tag): void {
    navigator.clipboard.writeText(`[${tag}]`);
    this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: { message: 'Tag Copied! Paste it into the content' },
      horizontalPosition: 'center',
      duration: helper.duration,
      panelClass: ['save-snackBar'],
      verticalPosition: 'top',
    });
  }
}
