import { Component, Inject } from '@angular/core';
import { CustomSnackbarDeleteComponent, helper } from 'src/app/shared';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { StudentProfileService } from 'src/app/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-child-result-modal',
  templateUrl: './child-result-modal.component.html',
  styleUrls: ['./child-result-modal.component.scss'],
})
export class ChildResultModalComponent {
  loading$ = new Subject<boolean>();
  activeProfileIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<ChildResultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _snackBar: MatSnackBar,
    private studentProfileService: StudentProfileService,
    private analyticsService: AnalyticsService,
  ) {}

  downloadReport(studentId) {
    this.loading$.next(true);
    this.studentProfileService.getResultReport(studentId).subscribe(
      (res: any) => {
        this.loading$.next(false);
        if (Object.keys(res.data).length !== 0) {
          const pdfSource = res.data.resultUrl;
          const newWindow = window.open(pdfSource, '_blank');
          if (!newWindow) {
            alert(
              'A pop-up blocker prevented opening the document. Please allow pop-ups and try again.',
            );
          }
          this.analyticsService.trackViewResult({
            studentId,
          });
        } else {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: res.message },
            horizontalPosition: 'center',
            duration: 2000,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
        }
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.loading$.next(false);
      },
    );
  }

  close() {
    this.dialogRef.close();
  }
}
