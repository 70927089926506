import { MatChipsModule, MatProgressSpinnerModule } from '@angular/material';
import { AdmissionSharedFooterComponent } from './admission-shared-footer/admission-shared-footer.component';
import { AdmissionSharedHeaderComponent } from './admission-shared-header/admission-shared-header.component';
import { CommonModule } from '@angular/common';
import { DynamicTagsComponent } from '../shared/components/dynamic-tags/dynamic-tags.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    AdmissionSharedHeaderComponent,
    AdmissionSharedFooterComponent,
    DynamicTagsComponent,
  ],
  imports: [CommonModule, MatChipsModule, MatProgressSpinnerModule],
  exports: [
    AdmissionSharedHeaderComponent,
    AdmissionSharedFooterComponent,
    DynamicTagsComponent,
    MatProgressSpinnerModule,
  ],
})
export class AdmissionSharedModule {}
