import { Component } from '@angular/core';

@Component({
  selector: 'app-admission-shared-footer',
  templateUrl: './admission-shared-footer.component.html',
  styleUrls: ['./admission-shared-footer.component.scss'],
})
export class AdmissionSharedFooterComponent {
  showFooter = false;
  viewAllNotes(action: string): void {
    if (action === 'cancel') {
      this.showFooter = !this.showFooter;
    }
  }
}
