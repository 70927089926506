import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countByKey',
  pure: false,
})
export class CountPipe implements PipeTransform {
  transform(items: any[], attr: string): any {
    if (!items || !Array.isArray(items) || items.length === 0 || !attr) {
      return 0; // Return 0 if the array is empty, null, or attr is not provided
    }

    return items.reduce((sum, item) => {
      const value = item && item[attr] ? item[attr].length : 0;
      return sum + value;
    }, 0);
  }
}