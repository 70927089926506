import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
} from '@angular/material';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {
  NgJoinPipeModule,
  NgOrderByPipeModule,
  NgWherePipeModule,
} from 'angular-pipes';
import { AcademicComponent } from './components/academic/academic.component';
import { AcademicHoldToBillDashboardComponent } from './components/dashboard-components/academic-hold-to-bill-dashboard/academic-hold-to-bill-dashboard.component';
import { AcademicListDashobardComponentComponent } from './components/dashboard-components/academic-list-dashobard-component/academic-list-dashobard-component.component';
import { AddMeetingLogModalComponent } from './components/add-meeting-log-modal/add-meeting-log-modal.component';
import { AdmissionCardComponent } from './components/admission-card/admission-card.component';
import { AdmissionSharedModule } from '../admission-shared/admission-shared.module';
import { AlphaNumericOnlyDirective } from './directives/alpha-numeric-only.directive';
import { AlphabetOnlyDirective } from './directives/alphabet-only.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AnnouncementSharedModalComponent } from './components/announcement-shared-modal/announcement-shared-modal.component';
import { AttachmentCardSharedComponent } from './components/attachment-card-shared/attachment-card-shared.component';
import { AttendanceCalenderComponent } from './components/attendance-calender/attendance-calender.component';
import { AttendanceMonthViewComponent } from './components/attendance-month-view/attendance-month-view.component';
import { AttendenceCardComponent } from './components/attendence-card/attendence-card.component';
import { AuthDirective } from './directives/auth.directive';
import { BehaviourComponent } from './components/behaviour/behaviour.component';
import { BillingCardComponent } from './components/billing-card/billing-card.component';
import { BillingComponent } from './components/billing/billing.component';
import { BillingListDashboardComponent } from './components/dashboard-components/billing-list-dashboard/billing-list-dashboard.component';
import { BillingStudentProfileBillingListComponent } from './components/billing-student-profile/billing-student-profile-billing-list/billing-student-profile-billing-list.component';
import { BillingStudentProfileComponent } from './components/billing-student-profile/billing-student-profile/billing-student-profile.component';
import { BillingStudentProfileDetailComponent } from './components/billing-student-profile/billing-student-profile-detail/billing-student-profile-detail.component';
import { ButtonNewModuleComponent } from './components/button-new-module/button-new-module.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CapitalizeDirective } from './pipes/capitalize.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { ChangeBranchModalComponent } from '../admin/student/change-student-branch/change-branch-modal/change-branch-modal.component';
import { ChangeGradeModalComponent } from '../admin/student/change-student-grade/change-grade-modal/change-grade-modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeSectionModalComponent } from '../admin/student/change-section/change-section-modal/change-section-modal.component';
import { ChecklistActionComponent } from './components/checklist-action/checklist-action.component';
import { ChequeDetailModalComponent } from './components/billing-student-profile/cheque-detail-modal/cheque-detail-modal.component';
import { ChildResultModalComponent } from './components/child-result-modal/child-result-modal.component';
import { ChildSelectorBoxComponent } from './components/child-selector-box/child-selector-box.component';
import { ChildrenSelectorComponent } from './components/children-selector/children-selector.component';
import { ClassListModalComponent } from '../admin/student/student-reports/class-list-modal/class-list-modal.component';
import { ClassStrengthModalComponent } from '../admin/student/student-reports/class-strength-modal/class-strength-modal.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { CommonModule } from '@angular/common';
import { CommunicationEventsComponent } from './components/shared-events/upcomming-events/communication-events.component';
import { CountPipe } from './pipes/count/count.pipe';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { CourseDetailsComponent } from './components/course-details/course-details.component';
import { CurrencyCommaFormatPipe } from './pipes/currency-comma-format.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format/currency-format.pipe';
import { CustomDirectiveModule } from './directives/customDirective.module';
import { CustomPipesModule } from '../admin/custom-pipes/custom-pipes.module';
import { CustomSnackBarComponent } from './components/custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from './components/custom-snackbar-delete/custom-snackbar-delete.component';
import { DashboardStatusListComponent } from './components/dashboard-components/dashboard-status-list/dashboard-status-list.component';
import { DashboardStreamAssignmentComponent } from './components/dashboard-stream-assignment/dashboard-stream-assignment.component';
import { DashboardStreamMaterialComponent } from './components/dashboard-stream-material/dashboard-stream-material.component';
import { DashboardStreamPostComponent } from './components/dashboard-stream-post/dashboard-stream-post.component';
import { DayViewComponent } from './components/shared-events/day-view/day-view.component';
import { DetailCardComponent } from './components/detail-card/detail-card.component';
import { DocumentAttachmentComponent } from './components/document-attachment/document-attachment.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Error404Component } from '../core/error-pages/error404/error404.component';
import { EventAddModalComponent } from './components/shared-events/event-add-modal/event-add-modal.component';
import { EventDetailModalComponent } from './components/shared-events/event-detail-modal/event-detail-modal.component';
import { EventDetailPopupComponent } from './components/shared-events/event-detail-popup/event-detail-popup.component';
import { EventMainDashboardComponent } from './components/shared-events/event-main-dashboard/event-main-dashboard.component';
import { EventsListModalComponent } from './components/events-list-modal/events-list-modal.component';
import { FlexibleTimetableCardComponent } from './components/flexible-timetable-card/flexible-timetable-card.component';
import { FlexibleTimetableResponsiveComponent } from './components/flexible-timetable-responsive/flexible-timetable-responsive.component';
import { GroupByPipe } from './pipes/groupBy/group-by.pipe';
import { HolidaysCardComponent } from './components/holidays-card/holidays-card.component';
import { ImageCropModalComponentComponent } from './image-crop-modal-component/image-crop-modal-component.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputFilterPipe } from './pipes/search/input-filter.pipe';
import { InsightsCardComponent } from './components/insights-card/insights-card.component';
import { InvoiceModificationModalComponent } from './components/billing-student-profile/invoice-modification-modal/invoice-modification-modal.component';
import { LetterCardComponent } from './components/letter-card/letter-card.component';
import { LightboxModule } from 'ngx-lightbox';
import { ListBoxComponent } from './components/dashboard-components/list-box/list-box.component';
import { ListDashboardCardComponent } from './components/dashboard-components/list-dashboard-card/list-dashboard-card.component';
import { ListNamCardCustomColumnsComponent } from './components/dashboard-components/list-nam-card-custom-columns/list-nam-card-custom-columns.component';
import { ListNameCardComponent } from './components/dashboard-components/list-name-card/list-name-card.component';
import { ListTableRecordsComponent } from './components/dashboard-components/list-table-records/list-table-records.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { LmsCardsComponent } from './components/lms-cards/lms-cards.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MeetingLogCardComponent } from './components/meeting-log-card/meeting-log-card.component';
import { MeetingLogsComponent } from './components/meeting-logs/meeting-logs.component';
import { MimeTypePipe } from './pipes/mimeType/mime-type.pipe';
import { MonthViewComponent } from './components/shared-events/month-view/month-view.component';
import { MonthYearViewComponent } from './components/shared-events/month-year-view/month-year-view.component';
// Shared Events
import { NewsListBoxComponent } from './components/dashboard-components/news-list-box/news-list-box.component';
import { NewsModalComponent } from './components/news-modal/news-modal.component';
import { NewsTabularListComponent } from './components/dashboard-components/news-tabular-list/news-tabular-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgTruncatePipeModule } from 'angular-pipes';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NumberPipe } from './pipes/number.pipe';
import { ParentProfileComponent } from './components/parent-profile/parent-profile.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PolicyCardComponent } from './components/policy-card/policy-card.component';
import { PreviewDocumentModalComponent } from './components/preview-document-modal/preview-document-modal.component';
import { PreviewMediaModalComponent } from './components/preview-media-modal/preview-media-modal.component';
import { ProgramCardComponent } from './components/program-card/program-card.component';
import { ProgressBoardComponent } from './components/dashboard-components/progress-board/progress-board.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { ProgressCircleIconComponent } from './components/progress-circle-icon/progress-circle-icon.component';
import { RequestFormCardComponent } from './components/request-form-card/request-form-card.component';
import { ResultConfettiComponent } from './components/result-confetti/result-confetti.component';
import { RichEditorComponent } from './components/rich-editor/rich-editor';
import { RouterModule } from '@angular/router';
import { SafeDirective } from './pipes/safe.directive';
import { SafePipe } from './pipes/safe.pipe';
import { SanitizeHTMLPipe } from './pipes/sanitizeHTML/sanitize-html.pipe';
import { SharedComponent } from './shared.component';
import { SocialCardsComponent } from './components/social-cards/social-cards.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StatsBoxComponent } from './components/dashboard-components/stats-box/stats-box.component';
import { StudentAverageBoxListComponent } from './components/dashboard-components/student-average-box-list/student-average-box-list.component';
import { StudentFilterPipe } from './pipes/search/studentFilter.pipe';
import { StudentMeetingLogsComponent } from './components/student-meeting-logs/student-meeting-logs.component';
import { StudentProfileComponent } from './components/student-profile/student-profile.component';
import { StudentProfileLetterComponent } from './components/student-profile-letter/student-profile-letter.component';
import { TabComponent } from './components/tabs-view/tab/tab.component';
import { TableComponent } from './table/table.component';
import { TablePaginatorComponent } from './components/table-paginator/table-paginator.component';
import { TabsComponent } from './components/tabs-view/tabs/tabs/tabs.component';
import { TeacherAddProfileComponent } from './components/teacher-form-shared/teacher-add/teacher-add-profile/teacher-add-profile.component';
import { TeacherBranchComponent } from './components/teacher-form-shared/teacher-add/teacher-branch/teacher-branch.component';
import { TeacherDocumentComponent } from './components/teacher-form-shared/teacher-add/teacher-document/teacher-document.component';
import { TeacherExperienceComponent } from './components/teacher-form-shared/teacher-add/teacher-experience/teacher-experience.component';
import { TeacherProfileSharedComponent } from './components/teacher-profile-shared/teacher-profile-shared.component';
import { TeacherQualificationComponent } from './components/teacher-form-shared/teacher-add/teacher-qualification/teacher-qualification.component';
import { TeacherTimetableViewComponent } from './components/teacher-timetable-view/teacher-timetable-view.component';
import { TimeTableCardComponent } from './components/time-table-card/time-table-card.component';
import { TimelineDashboardComponent } from './components/dashboard-components/timeline-dashboard/timeline-dashboard.component';
import { TimetableCardsComponent } from './components/timetable-cards/timetable-cards.component';
import { TimetableModalComponent } from './components/timetable/timetable-modal/timetable-modal.component';
import { TimetableScreenComponent } from '../parent-user/timetable-screen/timetable-screen.component';
import { TimetablecomComponent } from './components/timetable/timetablecom/timetablecom.component';
import { TimetableviewComponent } from './components/timetableview/timetableview.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UpcomingEventListComponent } from './components/upcoming-event-list/upcoming-event-list.component';
import { UserPostComponent } from './components/user-post/user-post.component';
import { ViewActivityComponent } from './components/dashboard-components/view-activity/view-activity.component';
import { ViewMeetingLogModalComponent } from './components/view-meeting-log-modal/view-meeting-log-modal.component';
import { ViewReactionModalComponent } from './components/view-reaction-modal/view-reaction-modal.component';
import { WeekViewComponent } from './components/shared-events/week-view/week-view.component';
import { YearViewComponent } from './components/shared-events/year-view/year-view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    Ng2SearchPipeModule,
    NgCircleProgressModule.forRoot(),
    NgDynamicBreadcrumbModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatTimepickerModule,
    MatProgressBarModule,
    MatRippleModule,
    NgSelectModule,
    AngularEditorModule,
    MatPaginatorModule,
    CarouselModule,
    MatTooltipModule,
    MatIconModule,
    MatDatepickerModule,
    NgxDropzoneModule,
    CdkTableModule,
    CdkStepperModule,
    // ClipboardModule,
    DragDropModule,
    MatSlideToggleModule,
    ClickOutsideModule,
    MatFormFieldModule,
    MatInputModule,
    NgOrderByPipeModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    NgWherePipeModule,
    MatMenuModule,
    MatExpansionModule,
    PdfViewerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    LightboxModule,
    NgTruncatePipeModule,
    CustomPipesModule,
    CustomDirectiveModule,
    NgJoinPipeModule,
    ImageCropperModule,
    MatChipsModule,
    AdmissionSharedModule,
  ],
  declarations: [
    TeacherTimetableViewComponent,
    ChequeDetailModalComponent,
    SharedComponent,
    InputFilterPipe,
    StudentFilterPipe,
    AuthDirective,
    SpinnerComponent,
    TimetablecomComponent,
    AlphabetOnlyDirective,
    CapitalizeDirective,
    TimetableModalComponent,
    ButtonNewModuleComponent,
    CustomSnackBarComponent,
    CustomSnackbarDeleteComponent,
    SpinnerComponent,
    SafeDirective,
    TimetableModalComponent,
    ButtonNewModuleComponent,
    CustomSnackBarComponent,
    CustomSnackbarDeleteComponent,
    SanitizeHTMLPipe,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    ListViewComponent,
    TablePaginatorComponent,
    AdmissionCardComponent,
    ProgramCardComponent,
    InsightsCardComponent,
    AttendenceCardComponent,
    BehaviourComponent,
    AcademicComponent,
    BillingComponent,
    SocialCardsComponent,
    TimeTableCardComponent,
    UpcomingEventListComponent,
    HolidaysCardComponent,
    RequestFormCardComponent,
    MeetingLogsComponent,
    MonthViewComponent,
    DayViewComponent,
    WeekViewComponent,
    CommunicationEventsComponent,
    EventAddModalComponent,
    EventDetailModalComponent,
    YearViewComponent,
    MonthYearViewComponent,
    DetailCardComponent,
    LmsCardsComponent,
    StatsBoxComponent,
    ListBoxComponent,
    TableComponent,
    TimelineDashboardComponent,
    NewsListBoxComponent,
    BillingListDashboardComponent,
    StudentAverageBoxListComponent,
    ListNameCardComponent,
    ProgressBoardComponent,
    NewsTabularListComponent,
    ListDashboardCardComponent,
    ListNamCardCustomColumnsComponent,
    ListTableRecordsComponent,
    DashboardStatusListComponent,
    ViewActivityComponent,
    ProgressCircleComponent,
    GroupByPipe,
    ProgressCircleIconComponent,
    CourseCardComponent,
    DocumentAttachmentComponent,
    ChecklistActionComponent,
    CurrencyFormatPipe,
    CalendarComponent,
    AcademicListDashobardComponentComponent,
    AlphaNumericOnlyDirective,
    ParentProfileComponent,
    BillingStudentProfileComponent,
    StudentProfileComponent,
    EventMainDashboardComponent,
    BillingStudentProfileBillingListComponent,
    BillingStudentProfileDetailComponent,
    SafePipe,
    EventDetailPopupComponent,
    TimetableviewComponent,
    ChildrenSelectorComponent,
    PreviewDocumentModalComponent,
    CourseDetailsComponent,
    UserPostComponent,
    PreviewMediaModalComponent,
    MeetingLogCardComponent,
    ViewMeetingLogModalComponent,
    ChangeBranchModalComponent,
    ClassListModalComponent,
    ClassStrengthModalComponent,
    ChangeGradeModalComponent,
    ChangeSectionModalComponent,
    StudentMeetingLogsComponent,
    NumberPipe,
    CurrencyCommaFormatPipe,
    LoadingComponent,
    BillingCardComponent,
    EventsListModalComponent,
    Error404Component,
    InvoiceModificationModalComponent,
    TimetableCardsComponent,
    TimetableScreenComponent,
    LetterCardComponent,
    StudentProfileLetterComponent,
    AttendanceMonthViewComponent,
    AttendanceCalenderComponent,
    AcademicHoldToBillDashboardComponent,
    PolicyCardComponent,
    FlexibleTimetableCardComponent,
    NewsModalComponent,
    AddMeetingLogModalComponent,
    ChangePasswordComponent,
    TeacherProfileSharedComponent,
    FlexibleTimetableResponsiveComponent,
    ResultConfettiComponent,
    TeacherAddProfileComponent,
    TeacherBranchComponent,
    TeacherQualificationComponent,
    TeacherExperienceComponent,
    TeacherDocumentComponent,
    ImageCropModalComponentComponent,
    AttachmentCardSharedComponent,
    ViewReactionModalComponent,
    MimeTypePipe,
    ChildSelectorBoxComponent,
    DashboardStreamPostComponent,
    DashboardStreamAssignmentComponent,
    DashboardStreamMaterialComponent,
    AnnouncementSharedModalComponent,
    CountPipe,
    ChildResultModalComponent,
    RichEditorComponent,
  ],
  entryComponents: [
    InvoiceModificationModalComponent,
    TimetableModalComponent,
    CustomSnackBarComponent,
    CustomSnackbarDeleteComponent,
    AdmissionCardComponent,
    ProgramCardComponent,
    InsightsCardComponent,
    AttendenceCardComponent,
    BehaviourComponent,
    AcademicComponent,
    BillingComponent,
    SocialCardsComponent,
    TimeTableCardComponent,
    UpcomingEventListComponent,
    HolidaysCardComponent,
    RequestFormCardComponent,
    MeetingLogsComponent,
    MonthViewComponent,
    DayViewComponent,
    WeekViewComponent,
    CommunicationEventsComponent,
    EventAddModalComponent,
    EventDetailModalComponent,
    LmsCardsComponent,
    EventMainDashboardComponent,
    PreviewDocumentModalComponent,
    UserPostComponent,
    ViewMeetingLogModalComponent,
    ChangeBranchModalComponent,
    ClassListModalComponent,
    ClassStrengthModalComponent,
    ChangeGradeModalComponent,
    ChangeSectionModalComponent,
    EventsListModalComponent,
    InvoiceModificationModalComponent,
    ChequeDetailModalComponent,
    NewsModalComponent,
    AddMeetingLogModalComponent,
    ImageCropModalComponentComponent,
    ViewReactionModalComponent,
    AnnouncementSharedModalComponent,
    ChildResultModalComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    CdkTableModule,
    CdkStepperModule,
    DragDropModule,
    MatSlideToggleModule,
    CourseCardComponent,
    MatExpansionModule,
    InputFilterPipe,
    MimeTypePipe,
    StudentFilterPipe,
    AngularEditorModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatTimepickerModule,
    SanitizeHTMLPipe,
    CountPipe,
    AlphabetOnlyDirective,
    CapitalizeDirective,
    MatSnackBarModule,
    MatRippleModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,
    MatCardModule,
    TimetablecomComponent,
    ButtonNewModuleComponent,
    NgCircleProgressModule,
    MatTabsModule,
    NgDynamicBreadcrumbModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatIconModule,
    NgSelectModule,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    ListViewComponent,
    TablePaginatorComponent,
    ClickOutsideModule,
    AdmissionCardComponent,
    ProgramCardComponent,
    InsightsCardComponent,
    AttendenceCardComponent,
    BehaviourComponent,
    AcademicComponent,
    BillingComponent,
    SocialCardsComponent,
    TimeTableCardComponent,
    UpcomingEventListComponent,
    HolidaysCardComponent,
    RequestFormCardComponent,
    MeetingLogsComponent,
    MonthViewComponent,
    DayViewComponent,
    WeekViewComponent,
    CommunicationEventsComponent,
    EventAddModalComponent,
    EventDetailModalComponent,
    YearViewComponent,
    MonthYearViewComponent,
    DetailCardComponent,
    LmsCardsComponent,
    StatsBoxComponent,
    TableComponent,
    TimelineDashboardComponent,
    NewsListBoxComponent,
    BillingListDashboardComponent,
    StudentAverageBoxListComponent,
    ListNameCardComponent,
    ProgressBoardComponent,
    MatProgressBarModule,
    NewsTabularListComponent,
    ListDashboardCardComponent,
    ListNamCardCustomColumnsComponent,
    ListTableRecordsComponent,
    DashboardStatusListComponent,
    ViewActivityComponent,
    ProgressCircleComponent,
    GroupByPipe,
    ProgressCircleIconComponent,
    DocumentAttachmentComponent,
    ChecklistActionComponent,
    CurrencyFormatPipe,
    CalendarComponent,
    AcademicListDashobardComponentComponent,
    MatChipsModule,
    AlphaNumericOnlyDirective,
    EventMainDashboardComponent,
    MatCheckboxModule,
    ChildrenSelectorComponent,
    TimetableviewComponent,
    PdfViewerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    LightboxModule,
    SafePipe,
    UserPostComponent,
    MeetingLogCardComponent,
    StudentMeetingLogsComponent,
    NumberPipe,
    BillingStudentProfileComponent,
    BillingStudentProfileBillingListComponent,
    BillingStudentProfileDetailComponent,
    LoadingComponent,
    CustomPipesModule,
    CustomDirectiveModule,
    CurrencyCommaFormatPipe,
    ParentProfileComponent,
    BillingCardComponent,
    InvoiceModificationModalComponent,
    StudentProfileLetterComponent,
    AttendanceMonthViewComponent,
    AttendanceCalenderComponent,
    AcademicHoldToBillDashboardComponent,
    PolicyCardComponent,
    FlexibleTimetableCardComponent,
    ResultConfettiComponent,
    NewsModalComponent,
    ChangePasswordComponent,
    TeacherProfileSharedComponent,
    AddMeetingLogModalComponent,
    TeacherTimetableViewComponent,
    TeacherAddProfileComponent,
    TeacherBranchComponent,
    TeacherQualificationComponent,
    TeacherExperienceComponent,
    TeacherDocumentComponent,
    TimetableScreenComponent,
    FlexibleTimetableResponsiveComponent,
    StudentProfileComponent,
    MatMenuModule,
    AdmissionSharedModule,
    ImageCropModalComponentComponent,
    AttachmentCardSharedComponent,
    ViewReactionModalComponent,
    ChildSelectorBoxComponent,
    DashboardStreamPostComponent,
    DashboardStreamAssignmentComponent,
    DashboardStreamMaterialComponent,
    AnnouncementSharedModalComponent,
    ChildResultModalComponent,
    RichEditorComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
