import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResultEvaluationGradingSetupService {
  private readonly controller = 'result-evaluation-grading-setup';

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  getByBranchGradeAndTerm(branchId, gradeId, academicTermId) {
    return this.generic.getData(
      `${this.controller}/branch/${branchId}/grade/${gradeId}/academicTerm/${academicTermId}`,
    );
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  update(body, id) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
}
